<template>
  <div>
    <TitleWrapper :filter-option="false" title="Notifications" :display-breadcrumb="true" />
    <div
      :class="$style.minHieght80"
      class="main-content bg-primary-white rounded-t-lg mt-5 p-5 border-border-color-lighter border"
    >
      <!-- DateSheet Publish Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for date sheet published
        </p>
      </div>
      <div v-if="!isLoadingdateSheetPublish" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType1 v-else :notification-list="dateSheetPublishList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingdateSheetPublish"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(dateSheetPublishRecordCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- Subject Exam Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for subject exam result
        </p>
      </div>
      <div v-if="!isLoadingsubjectExamResult" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>

        <NotificationTableType2 v-else :notification-list="subjectExamResultList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingsubjectExamResult"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(subjectExamResultCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- Exam Result Summary Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for exam result summary
        </p>
      </div>
      <div v-if="!isLoadingExamResultSummary" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType2 v-else :notification-list="examResultSummaryList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingExamResultSummary"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(examResultSummaryCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- Subject Exam Conducted Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for subject exam conducted
        </p>
      </div>
      <div v-if="!isLoadingSubjectExamConducted" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType2 v-else :notification-list="subjectExamConductedList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingSubjectExamConducted"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(subjectExamConductedCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- Upcoming Exam Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">Reminder for upcoming exam</p>
      </div>
      <div v-if="!isLoadingUpcomingExam" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType2 v-else :notification-list="upcomingExamList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingUpcomingExam"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(upcomingExamCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- Upcoming Subject Exam Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for upcoming subject exam
        </p>
      </div>
      <div v-if="!isLoadingUpcomigSubjectExam" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType2 v-else :notification-list="upcomingSubjectExamList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingUpcomigSubjectExam"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(upcomingSubjectExamCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />
    </div>
    <AddExamTemplate
      v-if="showAddDateSheetPublishModal"
      :show-model="showAddDateSheetPublishModal"
      label="date sheet publish"
      @handleClick="toggleShowAddDateSheetPublishModal"
      @update="addDaeSheetPublish"
    />
    <AddExamTemplateType2
      v-if="showAddSubjectExamResultModal"
      label="subject exam result"
      :show-model="showAddSubjectExamResultModal"
      @handleClick="toogleShowAddSubjectExamResultModal"
      @update="addSUbjectExamResult"
    />
    <AddExamTemplateType2
      v-if="showExamSummaryResultModal"
      label="exam result summary"
      :show-model="showExamSummaryResultModal"
      @handleClick="toggleShowExamSummaryResultModal"
      @update="addExamResultSummary"
    />
    <AddExamTemplate
      v-if="showExamSubjectConductedModal"
      :show-model="showExamSubjectConductedModal"
      label="subject exam conducted"
      @handleClick="toggleShowSubjectExamConductedModal"
      @update="addsubjectExamConducted"
    />
    <AddExamTemplateType2
      v-if="showUpcomingExamModal"
      label="upcoming exam"
      :show-model="showUpcomingExamModal"
      @handleClick="toogleShowUpcomingExamModal"
      @update="addupcomingExam"
    />
    <AddExamTemplateType2
      v-if="showUpcomingSubjectExamModal"
      label="upcoming subject exam"
      :show-model="showUpcomingSubjectExamModal"
      @handleClick="toggleShowUpcomingSubjectExamModal"
      @update="addupcomingSubjectExam"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import AddExamTemplate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import AddExamTemplateType2 from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationTableType1 from '@/src/components/Settings/notifications/NotificationTableType1.vue'
import NotificationTableType2 from '@/src/components/Settings/notifications/NotificationTableType2.vue'
/* Utils or mixins */
import GeneralUtil from '@src/mixins/general-mixins.js'
import { mapActions } from 'vuex'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'

export default {
  components: {
    TitleWrapper,
    Pagination,
    Loader,
    AddExamTemplate,
    AddExamTemplateType2,
    NotificationTableType1,
    NotificationTableType2,
    NoRecordFound,
  },

  mixins: [GeneralUtil],
  page: {
    title: 'Notification | Exam',
  },
  data() {
    return {
      notifications: 'notifications',
      isLoadingdateSheetPublish: false,
      isLoadingsubjectExamResult: false,
      isLoadingExamResultSummary: false,
      isLoadingSubjectExamConducted: false,
      isLoadingUpcomingExam: false,
      isLoadingUpcomigSubjectExam: false,
      dateSheetPublishRecordCount: 20,
      subjectExamResultCount: 20,
      examResultSummaryCount: 20,
      subjectExamConductedCount: 20,
      upcomingExamCount: 20,
      upcomingSubjectExamCount: 20,
      filteredRecordLimit: 5,
      showAddDateSheetPublishModal: false,
      showAddSubjectExamResultModal: false,
      showExamSummaryResultModal: false,
      showExamSubjectConductedModal: false,
      showUpcomingExamModal: false,
      showUpcomingSubjectExamModal: false,
      dateSheetPublishList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          days: '2',
          days_before: {
            id: '0',
            name: 'Before',
          },
          due_date: '2:00 pm',
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          days: '2',
          days_before: {
            id: '0',
            name: 'Before',
          },
          due_date: '2:00 pm',
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      subjectExamResultList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },

        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      examResultSummaryList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      subjectExamConductedList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          days: '2',
          days_before: {
            id: '0',
            name: 'Before',
          },
          due_date: '2:00 pm',
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          days: '2',
          days_before: {
            id: '0',
            name: 'Before',
          },
          due_date: '2:00 pm',
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      upcomingExamList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },

        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      upcomingSubjectExamList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
    }
  },
  created() {
    this.setRightBar()
  },
  methods: {
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'Notifications',
          buttons: [
            {
              title: 'Create New Notification',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'DATE_SHEET_PUBLISH',
                  action: {
                    func: () => {
                      this.toggleShowAddDateSheetPublishModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'SUB_EXAM_RESULT',
                  action: {
                    func: () => {
                      this.toogleShowAddSubjectExamResultModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'EXAM_RESULT_SUMMARY',
                  action: {
                    func: () => {
                      this.toggleShowExamSummaryResultModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'SUB_EXAM_CONDUCTED',
                  action: {
                    func: () => {
                      this.toggleShowSubjectExamConductedModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'UPCOMING_EXAM',
                  action: {
                    func: () => {
                      this.toogleShowUpcomingExamModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'UPCOMING_SUB_EXAM',
                  action: {
                    func: () => {
                      this.toggleShowUpcomingSubjectExamModal()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    addDaeSheetPublish(e) {
      this.dateSheetPublishList.push(e)
    },

    addSUbjectExamResult(e) {
      this.subjectExamResultList.push(e)
    },

    addExamResultSummary(e) {
      this.examResultSummaryList.push(e)
    },

    addsubjectExamConducted(e) {
      this.subjectExamConductedList.push(e)
    },

    addupcomingExam(e) {
      this.upcomingExamList.push(e)
    },

    addupcomingSubjectExam(e) {
      this.upcomingSubjectExamList.push(e)
    },

    filterRecord() {},

    toggleShowAddDateSheetPublishModal(eventName) {
      this.showAddDateSheetPublishModal = !this.showAddDateSheetPublishModal
    },

    toogleShowAddSubjectExamResultModal(eventName) {
      this.showAddSubjectExamResultModal = !this.showAddSubjectExamResultModal
    },

    toggleShowExamSummaryResultModal(eventName) {
      this.showExamSummaryResultModal = !this.showExamSummaryResultModal
    },
    toggleShowSubjectExamConductedModal(eventName) {
      this.showExamSubjectConductedModal = !this.showExamSubjectConductedModal
    },

    toogleShowUpcomingExamModal(eventName) {
      this.showUpcomingExamModal = !this.showUpcomingExamModal
    },

    toggleShowUpcomingSubjectExamModal(eventName) {
      this.showUpcomingSubjectExamModal = !this.showUpcomingSubjectExamModal
    },

    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
